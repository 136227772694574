<template>
  <div class="background">
    <PasswordResets />
  </div>
</template>

<script>
import PasswordResets from "@/components/elements/dashboard/password-resets/PasswordResets";

export default {
  name: "DocumentManagementPasswordResets",

  components: {
    PasswordResets,
  },

  data() {
    return {};
  },

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped></style>
