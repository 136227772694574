var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"pa-4 mt-5 pa-lg-7",attrs:{"elevation":"1","rounded":"lg"}},[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.passwordResets,"sort-by":"id","sort-desc":true,"page":_vm.page,"items-per-page":_vm.passwordResetsPerPage,"hide-default-footer":""},on:{"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v("Password Resets")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}}),_c('v-spacer'),_c('v-dialog',{attrs:{"persistent":"","max-width":"500px"},scopedSlots:_vm._u([(_vm.$can('password_reset_create'))?{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" New Password Reset ")])]}}:null],null,true),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-form',{ref:"form",attrs:{"lazy-validation":""}},[_c('v-row',[_c('v-col',{staticClass:"mt-n5",attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"rules":_vm.emailRules,"label":"Email","required":""},scopedSlots:_vm._u([{key:"label",fn:function(){return [_c('p',{staticClass:"body-1"},[_vm._v(" Email "),_c('span',{staticClass:"red--text"},[_vm._v("*")])])]},proxy:true}]),model:{value:(_vm.editedPasswordReset.email),callback:function ($$v) {_vm.$set(_vm.editedPasswordReset, "email", $$v)},expression:"editedPasswordReset.email"}})],1)],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"btn btn-primary"},on:{"click":_vm.close}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"btn btn-primary"},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogDelete),callback:function ($$v) {_vm.dialogDelete=$$v},expression:"dialogDelete"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v("Are you sure you want to delete this password reset?")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"btn btn-primary"},on:{"click":_vm.closeDelete}},[_vm._v("Cancel")]),_c('v-btn',{attrs:{"color":"btn btn-primary"},on:{"click":_vm.deletePasswordResetConfirm}},[_vm._v("OK")]),_c('v-spacer')],1)],1)],1)],1)]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$helpers.datetime(item.created_at))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(_vm.$can('password_reset_update'))?_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.editPasswordReset(item)}}},[_vm._v(" mdi-pencil ")]):_vm._e(),(_vm.$can('password_reset_delete'))?_c('v-icon',{attrs:{"small":"","color":"red"},on:{"click":function($event){return _vm.deletePasswordReset(item)}}},[_vm._v(" mdi-delete ")]):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" No Data Available ")])]},proxy:true}])}),_c('v-row',{staticClass:"text-center px-4 align-center mt-4",attrs:{"wrap":""}},[_c('v-col',{staticClass:"text-truncate",attrs:{"cols":"12","md":"2"}},[_vm._v(" Total "+_vm._s(_vm.totalRecords)+" records ")]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1),_c('v-col',{attrs:{"cols":"6","md":"3"}},[_c('v-select',{attrs:{"label":"Password Resets per page","item-text":"text","item-value":"value","items":_vm.perPageChoices,"dense":"","outlined":"","hide-details":"","return-object":""},model:{value:(_vm.perPageChoice),callback:function ($$v) {_vm.perPageChoice=$$v},expression:"perPageChoice"}})],1)],1)],1),_c('v-overlay',{attrs:{"value":_vm.overlay,"z-index":"1000"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"64"}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }