<template>
  <div>
    <v-card class="pa-4 mt-5 pa-lg-7" elevation="1" rounded="lg">
      <v-card flat>
        <v-data-table
          :headers="headers"
          :items="passwordResets"
          sort-by="id"
          :sort-desc="true"
          class="elevation-1"
          :page.sync="page"
          :items-per-page="passwordResetsPerPage"
          hide-default-footer
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-toolbar-title>Password Resets</v-toolbar-title>
              <v-divider class="mx-4" inset vertical></v-divider>
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" persistent max-width="500px">
                <template
                  v-if="$can('password_reset_create')"
                  v-slot:activator="{ on, attrs }"
                >
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    New Password Reset
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title>
                    <span class="text-h5">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-form ref="form" lazy-validation>
                        <v-row>
                          <!-- Email -->
                          <v-col class="mt-n5" cols="12">
                            <v-text-field
                              v-model="editedPasswordReset.email"
                              :rules="emailRules"
                              label="Email"
                              required
                            >
                              <template v-slot:label>
                                <p class="body-1">
                                  Email <span class="red--text">*</span>
                                </p>
                              </template>
                            </v-text-field>
                          </v-col>
                        </v-row>
                      </v-form>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="close">
                      Cancel
                    </v-btn>
                    <v-btn color="btn btn-primary" @click="save"> Save </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="text-h5"
                    >Are you sure you want to delete this password
                    reset?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="btn btn-primary" @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="btn btn-primary"
                      @click="deletePasswordResetConfirm"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>

          <!-- created_at -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.created_at="{ item }">
            {{ $helpers.datetime(item.created_at) }}
          </template>

          <!-- actions -->
          <!-- eslint-disable-next-line vue/valid-v-slot -->
          <template v-slot:item.actions="{ item }">
            <v-icon
              v-if="$can('password_reset_update')"
              small
              class="mr-2"
              @click="editPasswordReset(item)"
              color="primary"
            >
              mdi-pencil
            </v-icon>
            <v-icon
              v-if="$can('password_reset_delete')"
              small
              @click="deletePasswordReset(item)"
              color="red"
            >
              mdi-delete
            </v-icon>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary"> No Data Available </v-btn>
          </template>
        </v-data-table>

        <v-row class="text-center px-4 align-center mt-4" wrap>
          <v-col class="text-truncate" cols="12" md="2">
            Total {{ totalRecords }} records
          </v-col>

          <v-col cols="12" md="6">
            <v-pagination v-model="page" :length="pageCount"> </v-pagination>
          </v-col>

          <v-col cols="6" md="3">
            <v-select
              v-model="perPageChoice"
              label="Password Resets per page"
              item-text="text"
              item-value="value"
              :items="perPageChoices"
              dense
              outlined
              hide-details
              return-object
            >
            </v-select>
          </v-col>
        </v-row>
      </v-card>

      <!-- Overlay -->
      <v-overlay :value="overlay" z-index="1000">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DocumentManagementPasswordResets",

  props: {
    redirect: {
      default: "",
      type: String,
    },
  },

  data() {
    return {
      overlay: false,

      dialog: false,
      dialogDelete: false,
      headers: [
        { text: "Id", align: "start", value: "id" },
        { text: "Email", value: "email" },
        { text: "Token", value: "token" },
        { text: "Created At", value: "created_at" },
        // { text: "Updated At", value: "updated_at" },
        { text: "Actions", value: "actions", sortable: false },
      ],
      passwordResets: [],
      editedIndex: -1,
      editedPasswordReset: {
        id: 0,
        email: "",
        token: "",
        created_at: "",
        updated_at: "",
      },
      defaultPasswordReset: {
        id: 0,
        email: "",
        token: "",
        created_at: "",
        updated_at: "",
      },

      // email
      emailRules: [
        (v) => !!v || "Required",
        (v) =>
          /^(([^<>()[\]\\.,;:\s@']+(\.[^<>()\\[\]\\.,;:\s@']+)*)|('.+'))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
            v
          ) || "E-mail must be valid",
        (v) => v.length <= 100 || "Can not be more than 100 characters",
      ],

      page: 1,
      passwordResetsPerPage: 100,
      totalRecords: 0,
      pageCount: 0,
      perPageChoice: { text: "100 records/page", value: 100 },
      perPageChoices: [
        { text: "5 records/page", value: 5 },
        { text: "25 records/page", value: 25 },
        { text: "50 records/page", value: 50 },
        { text: "100 records/page", value: 100 },
        { text: "150 records/page", value: 150 },
        { text: "200 records/page", value: 200 },
      ],
    };
  },

  computed: {
    ...mapState({ auth: "auth" }),

    // formTitle
    formTitle() {
      return this.editedIndex === -1
        ? "New Password Reset"
        : "Edit Password Reset";
    },
  },

  watch: {
    // dialog
    dialog(val) {
      val || this.close();
    },

    // dialogDelete
    dialogDelete(val) {
      val || this.closeDelete();
    },

    // page
    page() {
      this.getApiPagedPasswordResets();
    },

    // passwordResetsPerPage
    passwordResetsPerPage() {
      this.getApiPagedPasswordResets();
    },

    // perPageChoice
    perPageChoice(val) {
      this.passwordResetsPerPage = val.value;
      this.page = 1;
    },
  },

  created() {
    if (!this.$can("password_reset_access")) {
      this.$router.push("/dashboard/inbox");
    }

    // this.getApiPasswordResets();
    this.getApiPagedPasswordResets();
  },

  methods: {
    ...mapMutations({
      resetAuthState: "auth/resetState",
    }),

    // validateForm
    validateForm() {
      this.$refs.form.validate();

      if (this.$refs.form.validate()) {
        if (this.editedIndex > -1) {
          this.updateApiPasswordReset();
        } else {
          this.createApiPasswordReset();
        }
      }
    },

    // editPasswordReset
    editPasswordReset(passwordReset) {
      this.editedIndex = this.passwordResets.indexOf(passwordReset);
      this.editedPasswordReset = Object.assign({}, passwordReset);
      this.dialog = true;
    },

    // deletePasswordReset
    deletePasswordReset(passwordReset) {
      this.editedIndex = this.passwordResets.indexOf(passwordReset);
      this.editedPasswordReset = Object.assign({}, passwordReset);
      this.dialogDelete = true;
    },

    // deletePasswordResetConfirm
    deletePasswordResetConfirm() {
      this.deleteApiPasswordReset();
    },

    // close
    close() {
      this.dialog = false;
      this.$nextTick(() => {
        this.editedPasswordReset = Object.assign({}, this.defaultPasswordReset);
        this.editedIndex = -1;
      });

      this.$refs.form.resetValidation();
    },

    // closeDelete
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedPasswordReset = Object.assign({}, this.defaultPasswordReset);
        this.editedIndex = -1;
      });
    },

    // save
    save() {
      this.validateForm();
    },

    // Get Api PasswordResets
    async getApiPasswordResets() {
      this.overlay = true;

      const res = await this.$helpers.getApiData("passwordresets");

      if (res.status == 200) {
        this.passwordResets = res.data;
      }

      this.overlay = false;
    },

    // Get Api Paged PasswordResets
    async getApiPagedPasswordResets() {
      this.overlay = true;

      const res = await this.$helpers.getApiData(
        `passwordresets/paged?page_no=${this.page}&page_size=${this.passwordResetsPerPage}`
      );

      if (res.status == 200) {
        this.passwordResets = res.data.data;
        this.totalRecords = res.data.item_count;
        this.pageCount = res.data.page_count;

        this.$vuetify.goTo(0);
      }

      this.overlay = false;
    },

    // Create Api Password Reset
    async createApiPasswordReset() {
      this.overlay = true;

      const res = await this.$helpers.createApiData(
        "passwordresets",
        {
          email: this.editedPasswordReset.email,
        },
        "Password Reset Created Successfully!"
      );

      if (res.status == 200) {
        this.passwordResets.push(res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Update Api Password Reset
    async updateApiPasswordReset() {
      this.overlay = true;

      const res = await this.$helpers.updateApiData(
        `passwordresets/${this.editedPasswordReset.id}`,
        {
          id: this.editedPasswordReset.id,
          email: this.editedPasswordReset.email,
        },
        "Password Reset Updated Successfully!"
      );

      if (res.status == 200) {
        Object.assign(this.passwordResets[this.editedIndex], res.data);

        this.close();
      }

      this.overlay = false;
    },

    // Delete Api Password Reset
    async deleteApiPasswordReset() {
      this.overlay = true;

      const res = await this.$helpers.deleteApiData(
        `passwordresets/${this.editedPasswordReset.id}`,
        "Password Reset Deleted Successfully!"
      );

      if (res.status == 200) {
        this.passwordResets.splice(this.editedIndex, 1);

        this.closeDelete();
      }

      this.overlay = false;
    },
  },
};
</script>

<style lang="scss" scoped>
//
</style>
